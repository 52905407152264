<!--
 * @Description: 
 * @Author: 
 * @Date: 2021-03-12 11:03:42
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 15:42:30
-->

<template>
  <div class="jion">
    <div class="banner">
      <!-- <img src="@/assets/images/banner-join.png" alt="" /> -->
      <div class="txt-inner wow bounceInRight">
        <h3>I want you</h3>
      </div>
    </div>
    <div class="cent">
      <div class="section2">
        <div class="inner-title wow fadeInUp">招聘岗位</div>
        <div class="inner-cent  wow fadeInUp">
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="head">
                  <h5>产品经理</h5>
                  <p class="eng">Product Manager</p>
                  <p class="post">
                    <span>部门:产品研发部</span><span>丨</span
                    ><span>招聘人数:2人</span>
                  </p>
                </div>
              </template>
              <dl class="join-content">
                <dd class="infos">
                  <h6>职位描述：</h6>
                  <p>1、负责产品核心模块的功能设计，把握产品核心价值，对模块进行重构，达到简单易用的目的；
                  </p>
                  <p>2、负责竞品的调研和分析，总结提炼功能共性，加强自身产品的创新，制定自己产品的优化方案和策略；
                  </p>
                  <p>3、持续收集、整理和分析产品上线后的用户反馈和使用数据，并进行分析提炼，抽象出共性的工具化产品。并能够从数据流动角度梳理和挖掘客户需求，为客户提供业务解决方案和增值服务；
                  </p>
                  <p>4、与客户服务团队、UI、开发、测试、等多个小组进行充分沟通协调，保证版本功能的高效迭代，满足客户需求，达到产品目标。
                  </p>
                </dd>
                <dd class="infos">
                  <h6>任职要求：</h6>
                  <p>
                  1、5年以上产品相关工作经验，必须有B端产品经验，对企业信息管理产品有经验的优先；
                  </p>
                  <p>2、有较强的书面文字及语言表达能力，能够熟练使用Axure、Mockplus、MindManager、Visio等工具；
                  </p>
                  <p>3、拥有创业的工作激情，认可公司文化，乐观向上的心态，能承受工作压力，有较强的自我驱动能力、可塑性和责任心；
                  </p>
                  <p>4、具有良好的沟通能力，严谨的逻辑思维能力，缜密的分析总结和抽象能力，较强的团队协作能力，能快速把握需求的核心要点；</p>
                  <p>5、面试时需携带需求文档、产品原型、产品访问地址等资料。
                  </p>
                </dd>
              </dl>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title">
                <div class="head">
                  <h5>JAVA开发工程师</h5>
                  <p class="eng">Java Development Engineer</p>
                  <p class="post">
                    <span>部门:产品研发部</span><span>丨</span
                    ><span>招聘人数:2人</span>
                  </p>
                </div>
              </template>
              <dl class="join-content">
                <dd class="infos">
                  <h6>任职要求：</h6>
                  <p>1、5年以上JAVA实际开发经验，计算机相关专业大专以上学历；</p>
                  <p>2、精通java,对Spring、SpringMVC、MyBatis、了解其原理和实现机制；了解java常用的数据结构原理及实现机制。</p>
                  <p>3、精通SQL，熟练使用MySQL、Oracle等数据库，并具有一定的SQL优化能力；精通redis、mongodb等优先。</p>
                  <p>4、熟悉常用消息中间件，如Kafka等</p>
                  <p>5、熟悉Tomcat等应用服务器的部署和配置，熟悉Linux操作系统常用命令；</p>
                  <p>6、具备良好的系统设计、文档、表达和沟通能力，能很好的融入团队工作；</p>
                  <p>7、工作责任心强，具有较强的问题分析和解决能力，善于沟通和总结、有良好的团队意识；</p>
                </dd>
              </dl>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title">
                <div class="head">
                  <h5>架构师</h5>
                  <p class="eng">Operations</p>
                  <p class="post">
                    <span>部门:产品研发部</span><span>丨</span
                    ><span>招聘人数:2人</span>
                  </p>
                </div>
              </template>
              <dl class="join-content">
                <dd class="infos">
                  <h6>职位描述：</h6>
                  <p>1、参与公司核心系统应用架构规划、设计，使系统体系化并具有技术前瞻；</p>
                  <p>2、分析产品功能，参与技术方案的讨论，负责完成重要业务模块及核心框架的搭建及编码实现；</p>
                  <p>3、协同他人组织跨团队沟通协作，确保系统架构内外设计合理或保障项目质量与进度；</p>
                  <p>4、引导团队用架构的思路设计和规划系统，使团队的架构设计能力更上一个层面；</p>
                  <p>5、帮助团队解决日常疑难问题。</p>
                </dd>
                <dd class="infos">
                  <h6>任职要求：</h6>
                  <p>1、JAVA基础扎实：熟练掌握数据结构、多线程编程，掌握常用的设计模式；熟悉JVM，包括内存模型、类加载机制以及性能优化；</p>
                  <p>2、精通HTTP编程，5年及以上使用Java语言进行HTTP相关开发经验，精通spring boot框架，熟悉前端开发（js、html、css）；</p>
                  <p>3、熟练运用spring cloud各组件及类似组件搭建微服务架构，对服务治理、架构软件管理、自动化运维、资源调度管理、整体架构监控、流程控制有实际的应用。（关键技能如：spring cloud/elasticsearch/RocketMQ/mongodb/mycat/TiDB/docker/kubernetes/prometheus等）</p>
                  <p>4、有大型业务系统的架构设计和实施能力，熟悉分布式系统的设计和应用，熟悉分布式、缓存、消息等机制、数据搜索引擎，能对分布式常用技术进行合理应用，解决实际架构问题；</p>
                  <p>5、有很强的分析复杂问题和解决复杂问题的能力，有强烈的责任心和使命感；</p>
                  <p>6、技术视野开阔，学习和抗压能力好，有很强的分析和解决复杂问题的能力，具备良好的沟通和组织协调能力</p>
                </dd>
              </dl>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title">
                <div class="head">
                  <h5>前端工程师</h5>
                  <p class="eng">Web Development Engineer</p>
                  <p class="post">
                    <span>部门:产品研发部</span><span>丨</span
                    ><span>招聘人数:2人</span>
                  </p>
                </div>
              </template>
              <dl class="join-content">
                <!-- <dt class="titles">职位描述：</dt> -->
                <dd class="infos">
                  <h6>职位描述：</h6>
                  <p>1、根据网页效果图，快速高效地完成页面的制作，并配合后端开发人员完成与业务层交互的脚本编写。</p>
                  <p>2、根据网页或App原型，给出最优的前端解决方案；完成页面布局、UI控件编写、AJAX交互等前端开发工作。</p>
                  <p>3、优化android和IOS软件产品用户体验和响应速度,提升软件产品的易用性。</p>
                  
                </dd>
                <dd class="infos">
                  <h6>任职要求：</h6>
                  <p>
                    1、本科及以上学历；
                  </p>
                  <p>
                    2、精通HTML5/ XHTML、CSS、JavaScript. Aj,x等网页制作技术；
                  </p>
                  <p>3、熟悉JQuery、Bootstrap等框架；</p>
                  <p>4、精通vue、element-ui、 nodejs、 npm等相关技术</p>
                  <p>5、熟练使用MUI、uniapp等组件进行h5的APP开发 ，能进行小程序的开发</p>
                  <p>6、熟悉移动端兼容性解决方案，能够解决多类型终端兼容问题；</p>
                </dd>
              </dl>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title">
                <div class="head">
                  <h5>业财融合顾问（初级）</h5>
                  <p class="eng">Product Implementation</p>
                  <p class="post">
                    <span>部门:客户成功部</span><span>丨</span
                    ><span>招聘人数:2人</span>
                  </p>
                </div>
              </template>
              <dl class="join-content">
                <dd class="infos">
                  <h6>职位描述：</h6>
                  <p>1.考过CMA(美国注册管理会计P1通过也可）、MACC、CPA等相关财务证书优先；</p>
                  <p>
                    2.具备财务管理思维；
                  </p>
                  <p>
                    3.对财务管理咨询工作感兴趣；
                  </p>
                </dd>
                <dd class="infos">
                  <h6>工作内容：</h6>
                  <p>协助财务管理咨询师相关工作；</p>
                </dd>
              </dl>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="joblis-notice">
        <div class="fixed-mod">
          <p><b class="col-notice">应聘流程及需知:</b></p>
          <p>
            首先请留意上面的招聘信息是否有合适你的职位。详细阅读职位要求并向我们发送你的个人简历以及相关作品，我们将安排专人评审您的应聘邮件，通过对简历进行筛选进入以下流程》面试/复试》确认入职时间》报到》试用》入职</p>
          <p><b>薪金要求：</b>请于邮件内写明或面议。</p>
          <p><b>上班时间：</b>周一至周五，早上9:00-12:00，下午13:30-18:00</p>
          <p><b>有意者请发简历至 hr@enfry.com 合则约见</b></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "Jion",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
/deep/ .el-collapse{
  border:0;
}
.joblis-notice{
  color:#333;
  text-align:left;
  background:#f1f6ff;
  padding-bottom:20px;
  p{
    font-size:14px;
    line-height:24px;
  }
  .col-notice{
    color:#f54646;
  }
}
.fixed-mod{
  width:1200px;
  margin:0 auto;
  padding: 30px 0;
}
.jion {
  // background: #f4f4f6;
}
.banner {
  background: url("~@/assets/images/banner-join.png") no-repeat center
    #fff;
  height: 698px;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin-left: -450px;
    display: block;
  }
  .txt-inner {
    text-align: center;
    position: absolute;
    right: 50%;
    bottom: 240px;
    margin-right: -530px;
    h3 {
      font-size: 120px;
      color:#276fdd;
      margin: 0;
      width:440px;
      text-align: right;
      letter-spacing: -2px;
      text-transform: uppercase;
    }
  }
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding-top: 66px;
}
.inner-cent {
  width: 1200px;
  text-align: left;
  margin: 60px auto 0;
}
.head {
  line-height: 24px;
  width: 100%;
  position: relative;
  &::after{
    width:26px;
    height:13px;
    content:"";
    position: absolute;
    top: 40px;
    right: 0;
    background: url("~@/assets/images/icons.png") no-repeat right center;
  }
  h5 {
    margin: 0;
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }
  p {
    margin: 5px 0 0 0;
  }
  .post {
    color: #666;
    font-size: 12px;
    span {
      margin-right: 10px;
    }
  }
  .address {
    color: #333;
  }
  .times {
    position: absolute;
    right: 0;
    color: #999;
    font-size: 12px;
    top: 30px;
  }
}
.join-content {
  color: #666;
  .titles {
    font-size: 14px;
  }
  h6 {
    font-weight: normal;
  }
  .infos {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
/deep/ .el-collapse-item.is-disabled .el-collapse-item__header {
  cursor: auto;
}
/deep/ .el-collapse-item__header {
  height: auto;
  padding: 24px 50px;
  border-bottom:0;
  background: none;
}
/deep/ .el-collapse-item__wrap {
  background: #f1f6ff;
  border-top: 1px solid #e9eef7;
  padding: 10px 50px 0;
}
/deep/ .el-collapse-item__arrow {
  display: none;
}
/deep/ .el-collapse-item{
  border:1px solid #dce5ec;
  margin-bottom:10px;
  border-radius:5px;
  &.is-active{
    background:#f1f6ff;
    border:1px solid #0183de;
    .head::after{
      transform:rotate(180deg);
    }
  }
}
dd {
  margin: 0;
}
@media (max-width: 750px) {
  .head::after{
    display: none;
  }
  .inner-title {
    font-size: 20px;
    line-height: 24px;
    padding-top: 30px;
  }
  .fixed-mod{
    width:100%;
    padding:0 20px;
  }
  .banner {
    height:258px;
    overflow: hidden;
    background-size: 246%;
    background-position: 23% 0;
    img {
      width: 80%;
      margin-left: -40%;
      bottom: -60px;
    }
    .txt-inner {
      right:40px;
      left:auto;
      margin-right: 0;
      top: 100px;
      text-align: center;
      display: none;
      h3 {
        font-size: 54px;
        margin: 0;
        width: 110px;
        text-align: right;
        letter-spacing: -8px;
      }
    }
  }
  .inner-cent {
    width: 90%;
    margin-top:20px;
  }
  .joblis-notice{
    padding-top:20px;
  }
  /deep/ .el-collapse-item__header{
    padding:20px;
  }
  /deep/ .el-collapse-item__content{
    padding-bottom:10px;
  }
  /deep/ .el-collapse-item__wrap{
    padding:0 20px;
  }
  .join-content h6{
    font-size:14px;
    font-weight: bold;
  }
}
</style>
